import React, { useEffect, useState, useRef } from "react";
import {
  SidebarWithIcons,
  Topbar,
  MobileNav,
  DropdownMenu,
  useDateHelpers,
} from "ui";
import { useRouter } from "next/router";
import {
  CogIcon,
  HomeIcon,
  UsersIcon,
  ViewGridIcon as ViewGridIconOutline,
  SelectorIcon,
  BellIcon,
  SupportIcon,
  TemplateIcon,
  GlobeIcon,
  LogoutIcon,
  ChartBarIcon,
} from "@heroicons/react/outline";
import NextLink from "./nextLink";
import EnvChooser from "./envChooser";
import { useTheme } from "next-themes";
import { clearEnvironments, setTeamEnvironments } from "store";
import {
  environmentsUserCanViewEnvironments,
  teamsUserCanViewTeams,
} from "services";
import { themeScheme } from "utils";
import { RootState, setTeams } from "store";
import {
  useSelector,
  shallowEqual,
  useDispatch,
  TypedUseSelectorHook,
} from "react-redux";
import Image from "next/image";
import NeptuneN from "../neptuneN";
import { formatInTimeZone } from "date-fns-tz";
import Avvvatars from "avvvatars-react";
import { SunIcon, MoonIcon } from "@heroicons/react/solid";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const dummyUser = {
  name: "Whitney Francis",
  email: "whitneyfrancis@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const leftNavigation = [
  {
    name: "Wiredup",
    key: "teams",
    labelClasses:
      "flex items-center py-2 px-4 dark:bg-gray-800 bg-gray-100 rounded-md",
    children: [
      { name: "Technical Support", href: "#", key: "teams-1" },
      { name: "Sales", href: "#", key: "teams-2" },
      { name: "General", href: "#", key: "teams-3" },
    ],
  },
  {
    name: "Enviroments",
    key: "environments",
    href: "#",
    children: [
      { name: "Technical Support", href: "#", key: "environments-1" },
      { name: "Sales", href: "#", key: "environments-2" },
      { name: "General", href: "#", key: "environments-3" },
    ],
  },
  { name: "Reporter", href: "#", children: [], key: "environments-4" },
  //   { name: 'Settings', href: '#', children: [] },
];

const rightNavigation = [
  {
    name: "Configuration",
    key: "environments-5",
    href: "#",
    children: [
      { name: "Technical Support", href: "#", key: "environments-6" },
      { name: "Sales", href: "#", key: "environments-7" },
      { name: "General", href: "#", key: "environments-8" },
    ],
  },
  // { name: 'Reporting', href: '#', children: [] },
  //   { name: 'Settings', href: '#', children: [] },
];

const userNavigation = [
  { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];

const Sidebar = function () {
  const router = useRouter();
  const { theme, setTheme } = useTheme();

  const { getCurrentTime } = useDateHelpers();

  const { user } = useAppSelector(
    ({ team, auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const [navigation, setNavigation] = useState([
    {
      name: "Home",
      href: "/",
      matchingPaths: ["/", "/"],
      icon: HomeIcon,
      current: true,
    },
    {
      name: "Stream",
      href: "/stream",
      matchingPaths: ["/stream", "/stream/[uuid]"],
      icon: ViewGridIconOutline,
      current: false,
    },
    {
      name: "Analyze",
      href: "/analyze",
      matchingPaths: ["/analyze", "/analyze/[uuid]"],
      icon: ChartBarIcon,
      current: false,
    },
    {
      name: "Users",
      href: "/users",
      matchingPaths: ["/users", "/users/[uuid]"],
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Events",
      href: "/events",
      matchingPaths: ["/events", "/events/[uuid]"],
      icon: GlobeIcon,
      current: false,
    },
    {
      name: "Templates",
      href: "/templates",
      matchingPaths: ["/templates", "/templates/[uuid]"],
      icon: TemplateIcon,
      current: false,
    },
  ]);

  useEffect(() => {
    setNavigation((prevItems) => {
      // console.log("router.pathname", router.pathname);

      let newItems = prevItems.map((p) => {
        p.current = p.matchingPaths.includes(router.pathname) ? true : false;
        return p;
      });

      return newItems;
    });
  }, [router]);

  return (
    <>
      <SidebarWithIcons
        navigation={navigation}
        LinkRenderer={(props) => {
          return <NextLink className="font-semibold text-red-500" {...props} />;
        }}
        theme={theme}
        setTheme={setTheme}
        user={user}
        logo={Logo}
        currentTime={getCurrentTime().toFormat(
          "hh:mm:ss a, EEE, MMM dd, yyyy ZZZZ"
        )}
        // logoutNavigation={{
        //   name: "Logout",
        //   href: `${process.env.NEXT_PUBLIC_ACCESS_URL}/logout`,
        //   icon: LogoutIcon,
        // }}
      />
    </>
  );
};

const Logo = () => {
  return (
    <>
      <div className="mx-auto h-full w-full flex flex-col items-center justify-center bg-gradient-to-r from-white via-white to-white dark:from-gray-900 dark:via-gray-900 dark:to-gray-900">
        <NeptuneN className="text-gray-600 dark:text-gray-300" height={"32"} />
        <div
          className={`text-xs mt-1 leading-none font-logo lowercase text-gray-600 dark:text-gray-300`}
        >
          Neptune
        </div>
      </div>
    </>
  );
};

const EnvironmentChooser = ({ currentEnvironment, environments }) => {
  const dispatch = useDispatch();
  const [environmentItem, setEnvironmentItem] = useState(null);

  const onEnvironmentDropdown = async () => {
    const teamEnvironments = await environmentsUserCanViewEnvironments();
    await Promise.all([dispatch(setTeamEnvironments(teamEnvironments))]);
  };

  useEffect(() => {
    let environmentChildren = environments.map((environment) => {
      return {
        name: environment["environments*identifier"],
        href: `/teams/switch/${environment["teams*uuid"]}`,
        key: environment["environments*uuid"],
      };
    });

    if (environmentChildren.length === 0) {
      // dispatch(clearEnvironments());
      environmentChildren.push({
        name: "You need to create environments",
        key: "create-environment-label",
        spanClasses: `block px-4 py-2 text-xs text-blue-400 font-normal border-b ${themeScheme.border}`,
        isDisabled: true,
      });
    }

    setEnvironmentItem(() => {
      return {
        helper: "env",
        name: currentEnvironment
          ? currentEnvironment["name"]
          : "Choose Environment",
        key: "environment-switcher",
        labelClasses:
          "flex items-center py-2 dark:bg-gray-800 bg-gray-50 rounded-md",
        children: [
          {
            name: "Choose Environment",
            key: "choose-environment-label",
            isDisabled: true,
          },
          ...environmentChildren,
        ],
      };
    });
  }, [currentEnvironment, environments]);

  if (environmentItem) {
    return (
      <DropdownMenu
        LinkRenderer={EnvChooser}
        item={environmentItem}
        onButtonPress={onEnvironmentDropdown}
      />
    );
  } else {
    return null;
  }
};

const TeamChooser = ({ teams, currentTeam, enableTeamChooser }) => {
  const dispatch = useDispatch();
  const [teamItem, setTeamItem] = useState(null);

  const onTeamDropdown = async () => {
    const teams = await teamsUserCanViewTeams("neptune");
    dispatch(setTeams(teams));
  };

  useEffect(() => {
    let teamChildren = teams.map((team) => {
      return {
        name: team["teams*name"],
        href: `/teams/switch/${team["teams*uuid"]}`,
        key: team["teams*uuid"],
      };
    });

    setTeamItem(() => {
      return {
        helper: "team",
        name: currentTeam ? currentTeam["teams*name"] : "Choose Team",
        key: "teams-switcher",
        labelClasses:
          "flex items-center py-2 border-0 dark:bg-gray-800 bg-gray-50 rounded-md",
        children: [
          { name: "Choose Team", key: "choose-team-label", isDisabled: true },
          ...teamChildren,
        ],
      };
    });
  }, [teams, currentTeam, enableTeamChooser]);

  if (!enableTeamChooser) {
    return null;
  }

  if (teamItem) {
    return (
      <DropdownMenu
        LinkRenderer={NextLink}
        item={teamItem}
        onButtonPress={onTeamDropdown}
        ChevronIconComponent={SelectorIcon}
      />
    );
  } else {
    return null;
  }
};

const Header = ({
  teams = [],
  currentTeam = null,
  environments = [],
  currentEnvironment = null,
  user,
  enableTeamChooser = true,
  disableLeft = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [leftCustomItems, setLeftCustomItems] = useState(null);
  const [rightCustomItems, setRightCustomItems] = useState(null);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    if (disableLeft === false) {
      setLeftCustomItems(() => {
        return (
          <>
            {teams.length > 0 && (
              <TeamChooser
                teams={teams}
                currentTeam={currentTeam}
                enableTeamChooser={enableTeamChooser}
              />
            )}
            {currentTeam && (
              <>
                <div className="text-gray-200 dark:text-gray-700">/</div>
                <EnvironmentChooser
                  currentEnvironment={currentEnvironment}
                  environments={environments}
                />
              </>
            )}
          </>
        );
      });
    }
  }, [teams, currentTeam, currentEnvironment, environments, enableTeamChooser]);

  useEffect(() => {
    setRightCustomItems(() => {
      return (
        <>
          {currentTeam && (
            <DropdownMenu
              openTo="origin-top-right absolute z-30 right-0"
              item={{
                name: "Configuration",
                key: "configuration",
                children: [
                  {
                    name: "Environments",
                    href: "/environments",
                    key: "configuration-1",
                  },
                  {
                    name: "Providers",
                    href: "/providers",
                    key: "configuration-2",
                  },
                  {
                    name: "Notification Deliveries",
                    href: "/deliveries",
                    key: "configuration-3",
                  },
                  {
                    name: "Events Graveyard",
                    href: "/events/graveyard",
                    key: "configuration-4",
                  },
                ],
              }}
              LinkRenderer={NextLink}
            />
          )}

          <span className="inline-flex">
            <button
              onClick={() => {
                setTheme(theme === "light" ? "dark" : "light");
              }}
              className={`-mx-1 ${themeScheme.bg} p-1 rounded-full text-gray-400 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300`}
            >
              {theme === "dark" && (
                <MoonIcon
                  className={
                    "text-blue-400 h-8 w-8 mx-auto p-1 rounded-full hover:bg-blue-800"
                  }
                />
              )}

              {theme === "light" && (
                <SunIcon
                  className={
                    "text-blue-400 h-8 w-8 p-1 mx-auto rounded-full hover:bg-blue-50"
                  }
                />
              )}
            </button>
          </span>

          <span className="inline-flex hidden">
            <button
              onClick={() => {}}
              type="button"
              className={`-mx-1 ${themeScheme.bg} p-1 rounded-full text-gray-400 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300`}
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </span>

          <DropdownMenu
            openTo="origin-top-right absolute z-30 right-0"
            item={{
              name: "",
              key: "user-profile",
              children: [
                {
                  name: "Your Profile",
                  href: `${process.env.NEXT_PUBLIC_ACCESS_URL}/profile`,
                  key: "profile-1",
                },
                { name: "Teams", href: "/teams", key: "profile-3" },
                { name: "Invitations", href: "/invitations", key: "profile-4" },
                {
                  name: "Logout",
                  href: `${process.env.NEXT_PUBLIC_ACCESS_URL}/logout`,
                  key: "profile-5",
                },
              ],
            }}
            LinkRenderer={NextLink}
            labelComponent={() => {
              return (
                <>
                  <span className="sr-only">Open user menu</span>
                  <Avvvatars value={user?.attributes[0]?.value} />
                </>
              );
            }}
          />
        </>
      );
    });
  }, [theme]);

  return (
    <Topbar
      leftCustomItems={leftCustomItems}
      rightCustomItems={rightCustomItems}
      leftNavigation={[]}
      rightNavigation={[]}
      userNavigation={userNavigation}
      LinkRenderer={NextLink}
      user={user}
    />
  );
};

const MobileNavigation = ({ open, setOpen }) => {
  const router = useRouter();
  const { theme, setTheme } = useTheme();

  const [navigation, setNavigation] = useState([
    { name: "Home", href: "/", icon: HomeIcon, current: true },
    {
      name: "Stream",
      href: "/stream",
      icon: ViewGridIconOutline,
      current: false,
    },
    {
      name: "Analyze",
      href: "/analyze",
      icon: ViewGridIconOutline,
      current: false,
    },
    {
      name: "Users",
      href: "/users",
      icon: UsersIcon,
      current: false,
    },
    { name: "Settings", href: "/settings", icon: CogIcon, current: false },
  ]);

  useEffect(() => {
    setNavigation((prevItems) => {
      let newItems = prevItems.map((p) => {
        p.current = router.pathname === p.href ? true : false;
        return p;
      });

      return newItems;
    });
  }, [router]);

  return (
    <MobileNav
      open={open}
      setOpen={(v) => {
        setOpen(v);
      }}
      navigation={navigation}
      theme={theme}
      setTheme={setTheme}
      leftNavigation={leftNavigation}
      rightNavigation={rightNavigation}
      userNavigation={userNavigation}
      LinkRenderer={NextLink}
      user={dummyUser}
      logo={() => {
        return <div className={`text-gray-900 dark:text-white`}>Wiredup</div>;
      }}
    />
  );
};

export { Sidebar, Header, MobileNavigation };
