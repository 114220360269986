import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setCurrentEnvironment } from "store";

const EnvChooser = (props: any) => {
  const dispatch = useDispatch();

  let { children, item, child, active, className, onClick, ...rest } = props;

  const setCurrentEnvironmentForTeam = async (env) => {
    try {
      await Promise.all([dispatch(setCurrentEnvironment(env))]);
    } catch (error) {}
  };

  return (
    <button
      type="button"
      className={`w-full text-left ${className}`}
      {...rest}
      onClick={() => {
        setCurrentEnvironmentForTeam(child);
        onClick();
      }}
    >
      {children}
    </button>
  );
};

export default EnvChooser;
