import * as React from "react"

const SvgComponent = (props) => (


    <svg
        width={340}
        height={340}
        viewBox="0 0 340 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M70 130v190c0 5.523-4.477 10-10 10H20c-5.523 0-10-4.477-10-10V20c0-5.523 4.477-10 10-10h44.457a10 10 0 0 1 8.48 4.7l194.125 310.6a10.002 10.002 0 0 0 8.48 4.7H320c5.523 0 10-4.477 10-10V20c0-5.523-4.477-10-10-10h-40c-5.523 0-10 4.477-10 10v190"
            stroke="currentColor"
            strokeWidth={20}
        />
        <rect x={55} y={101} width={30} height={30} rx={3} fill="currentColor" />
        <rect x={255} y={209} width={30} height={30} rx={3} fill="currentColor" />
    </svg>

)

export default SvgComponent
