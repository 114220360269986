import React, { useEffect, useState } from "react";
import Link from "next/link";
import { classNames } from "ui";

const NextLink = (props: any) => {
  let { href, children, item, active, className, ...rest } = props;
  return (
    <Link href={href}>
      <a
        {...rest}
        className={classNames(
          "initial:text-sm initial:font-medium tracking-tight",
          "initial:text-blue-500 initial:hover:text-blue-600 initial:dark:text-blue-400 initial:dark:hover:text-blue-300",
          className
        )}
      >
        {children}
      </a>
    </Link>
  );
};

export default NextLink;
